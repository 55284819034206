
@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Black.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

#cover-right-widget {
  margin: 0;
  font-family: 'Sailec', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#cover-right-widget p {
  padding: 0;
  margin: 0;
  color: #1C434F;
}

#cover-right-widget strong, #cover-right-widget b {
  font-weight: 700;
}

.cr-load{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);
  /*change these sizes to fit into your project*/
  width:100px;
  height:100px;
}
.cr-load hr{border:0;margin:0;width:40%;height:40%;position:absolute;border-radius:50%;animation:spin 2s ease infinite}

.cr-load :first-child{background:#5ECB9E;animation-delay:-1.5s}
.cr-load :nth-child(2){background:#029094;animation-delay:-1s}
.cr-load :nth-child(3){background:#5ECB9E;animation-delay:-0.5s}
.cr-load :last-child{background:#029094}

@keyframes spin{
  0%,100%{transform:translate(0)}
  25%{transform:translate(160%)}
  50%{transform:translate(160%, 160%)}
  75%{transform:translate(0, 160%)}
}
